import { template as template_eeec1886e73a47ee86f68dca639aee6d } from "@ember/template-compiler";
const FKText = template_eeec1886e73a47ee86f68dca639aee6d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
