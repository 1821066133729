import { template as template_ef69766106ae46df92a2297142856d32 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ef69766106ae46df92a2297142856d32(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
